import Link from 'next/link';
import { useRouter } from 'next/router';
import { twMerge } from 'tailwind-merge';

import type { Button } from '@root/sanity/sanity.types';
import { appendQueryString } from '@root/src/utils/utils';

type SanityButtonProps = Omit<Button, '_type'> & {
  _type?: string;
} & ClassNameProp;

const SanityButton = ({
  className,
  href,
  includeQueryString,
  newTab,
  style,
  text,
  trackerClass,
}: SanityButtonProps): JSX.Element | null => {
  if (!href || !text) return null;

  const router = useRouter();

  if (includeQueryString) {
    href = appendQueryString(href, router.asPath);
  }

  return (
    <Link href={href}>
      <a
        className={twMerge('btn', style, trackerClass, className)}
        {...(newTab && { target: '_blank', rel: 'noopener noreferrer' })}
      >
        {text}
      </a>
    </Link>
  );
};

export default SanityButton;
