import useYotpoInit from '@root/src/hooks/useYotpoInit';

type Props = {
  alignment: string;
  showCountOnNewLine?: boolean;
};

const YotpoStarRatings = ({ alignment, showCountOnNewLine = false }: Props) => {
  useYotpoInit();

  const position = alignment === 'left' ? '' : alignment === 'center' ? 'justify-center' : 'justify-end';

  return (
    <div className={`flex ${position} ${showCountOnNewLine ? 'with-count-below-stars' : ''}`} suppressHydrationWarning>
      <div
        className="yotpo bottomLine w-fit pointer-events-none"
        data-domain={process.env.NEXT_PUBLIC_LOCAL_URL}
        data-appkey={process.env.NEXT_PUBLIC_YOTPO_APP_KEY}
        suppressHydrationWarning
      ></div>
    </div>
  );
};

export default YotpoStarRatings;
