import { PropsWithChildren } from 'react';
import { Disclosure } from '@headlessui/react';
import IconTriangleDown from './IconTriangleDown';
import AnimateHeight from './AnimateHeight';

type FaqItemProps = PropsWithChildren<{
  question: string;
  trackerClass: string;
}>;

const FaqItem = ({ children, question, trackerClass }: FaqItemProps) => {
  return (
    <Disclosure as="div" className="border-b border-gray-light">
      {({ open }) => (
        <>
          <Disclosure.Button className={`group flex w-full items-center justify-between py-16 ${trackerClass}`}>
            <h3 className="flex-grow pr-16 text-left text-gold-darker group-hover:text-black">{question}</h3>

            <IconTriangleDown
              className={`flex-shrink-0 fill-current transition  duration-150 group-hover:text-gold-darker ${
                open ? '' : 'rotate-90 transform'
              }`}
            />
          </Disclosure.Button>

          <AnimateHeight open={open}>
            <Disclosure.Panel static className="ml-32 space-y-16 pb-32">
              {children}
            </Disclosure.Panel>
          </AnimateHeight>
        </>
      )}
    </Disclosure>
  );
};

export default FaqItem;
