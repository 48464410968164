import React from 'react';
import { Breakpoints } from '../types';
import { buildPictureAttributes } from './pictureUitls';

type PictureProps = {
  alt: string;
  focalPointX?: number;
  focalPointY?: number;
  focalPointZ?: number; // range 1-100
  flip?: 'h' | 'v' | 'hv' | 'none';
  hero?: boolean;
  imageType?: string;
  noAspectRatioClass?: boolean;
  polaroid?: boolean;
  srcSet?: Breakpoints;
  url: string;
} & ClassNameProp &
  DataTestIdProp;

const Picture = ({
  alt,
  className = '',
  flip,
  focalPointX = 0.5,
  focalPointY = 0.3,
  focalPointZ = 1,
  hero = false,
  imageType = 'jpg',
  noAspectRatioClass = false,
  polaroid = false,
  srcSet,
  url,
  ...props
}: PictureProps) => {
  const imgWidths: Breakpoints = {
    base: hero ? 343 : srcSet && srcSet.base ? srcSet.base : 284,
    xs: hero ? 299 : srcSet && srcSet.xs ? srcSet.xs : 254,
    sm: hero ? 381 : srcSet && srcSet.sm ? srcSet.sm : 322,
    md: hero ? 436 : srcSet && srcSet.md ? srcSet.md : 358,
    lg: hero ? 580 : srcSet && srcSet.lg ? srcSet.lg : 376,
    xl: hero ? 720 : srcSet && srcSet.xl ? srcSet.xl : 396,
  };
  const polaroidClass = `polaroid`;

  const {
    aspectRatios,
    chunkedClasses,
    className: classWithBase,
    hasValidAr,
    screens,
  } = buildPictureAttributes(className);

  const showWarnings = !hasValidAr && process.env.ENVIRONMENT !== 'production';

  const IMGIX_MESSAGE =
    'monochrome=ffffffff&blur=600&blend-mode=lighten&blend64=YmJiYmJi&txt=Unsupported%20Aspect%20Ratio&txt-size=40&txt-color=ff0000&txt-align=middle,center&txt-font=Futura%20Condensed%20Medium';

  if (showWarnings) {
    console.error(
      `Image uses an unsupported aspect ratio. Either edit the image to match one of the ratios already defined in the Tailwind theme file or define a new one.`
    );
  }

  type ImageUrl = {
    aspectRatio: typeof aspectRatios[keyof Breakpoints];
    dpr: 1 | 2;
    flip?: PictureProps['flip'];
    focalPointX: number;
    focalPointY: number;
    focalPointZ: number;
    message?: string;
    url: string;
    width: number;
  };

  const imgType = (type: string | undefined) => {
    if (type) {
      type = type.toLowerCase();
    }
    switch (type) {
      case 'png':
        return 'png';
      default:
        return 'jpg';
    }
  };

  function buildImageUrl({
    aspectRatio,
    dpr,
    flip,
    focalPointX,
    focalPointY,
    focalPointZ,
    imageType,
    message,
    url,
    width,
  }: ImageUrl & { imageType?: string }): string {
    if (url.includes('sanity')) {
      return `${url}?fm=${imgType(imageType)}&fit=crop&crop=focalpoint${
        flip ? `&flip=${flip}` : ''
      }&fp-x=${focalPointX}&fp-y=${focalPointY}&ar=${aspectRatio}&w=${width}&auto=format&dpr=${dpr}`;
    }
    if (url.includes('imgix')) {
      return `${url}?fm=${imgType(imageType)}&fit=crop&crop=fp&flip=${
        flip ?? 'none'
      }&fp-x=${focalPointX}&fp-y=${focalPointY}&fp-z=${focalPointZ}&ar=${aspectRatio}&w=${width}&auto=format&dpr=${dpr}&${
        message ? IMGIX_MESSAGE : ''
      }`;
    } else throw new Error(`Image ${url} does not belong to our allowed hosts`);
  }

  return (
    <div {...props} className={`block w-full ${polaroid ? polaroidClass : ''} ${chunkedClasses.noMatch?.join(' ')}`}>
      <div
        className={
          noAspectRatioClass
            ? classWithBase.filter((classNameStr) => classNameStr !== 'aspect-ratio-7by5').join(' ')
            : classWithBase.join(' ')
        }
      >
        <picture className={`inline h-auto w-auto`}>
          <>
            {Object.keys(aspectRatios)
              .reverse()
              .map((elm) => (
                <source
                  key={elm}
                  media={elm === 'base' ? undefined : `(min-width: ${screens[elm as keyof Breakpoints]})`}
                  srcSet={`${buildImageUrl({
                    url,
                    flip,
                    focalPointX,
                    focalPointY,
                    focalPointZ,
                    aspectRatio: aspectRatios[elm as keyof Breakpoints],
                    dpr: 1,
                    width: imgWidths[elm as keyof Breakpoints] as number,
                    message: showWarnings ? IMGIX_MESSAGE : undefined, // @todo
                    imageType: imageType,
                  })} 1x,
                  ${buildImageUrl({
                    url,
                    flip,
                    focalPointX,
                    focalPointY,
                    focalPointZ,
                    aspectRatio: aspectRatios[elm as keyof Breakpoints],
                    dpr: 2,
                    width: imgWidths[elm as keyof Breakpoints] as number,
                    message: showWarnings ? IMGIX_MESSAGE : undefined, // @todo
                    imageType: imageType,
                  })} 2x`}
                />
              ))}

            <img className={`block h-full w-full object-cover`} loading="lazy" alt={alt} />
          </>
        </picture>
      </div>
    </div>
  );
};

export default Picture;
