import { RefObject, useEffect } from 'react';

export interface ClickOutsideCallback {
  (event?: MouseEvent): void;
}

// Hook for clicking outside element events
export default function useClickOutside(ref: RefObject<Node>, handler: ClickOutsideCallback) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const target = event.target as Node;

      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('click', listener, { capture: true });

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [ref, handler]);
}
