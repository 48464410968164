import React from 'react';
import Line from './Line';

interface FaqGroupProps {
  title?: string;
  prominent?: boolean;
}

type Props = React.FC<React.HTMLProps<HTMLDivElement> & FaqGroupProps>;

const FaqGroup: Props = ({ title, className, children, prominent = false, ...props }) => {
  const heading = prominent ? (
    <div className="mb-32 text-center">
      <h2 className="text-h1-display">{title}</h2>
    </div>
  ) : (
    <>
      <h2 className="text-h2">{title}</h2>
      <Line className="mb-0 sm:mb-0" />
    </>
  );

  return (
    <div {...props} className={`${className ? className : ''}`}>
      <div>
        {title && heading}

        <div>{children}</div>
      </div>
    </div>
  );
};

export default FaqGroup;
