import React from 'react';
import SalesCallout from './SalesCallout';
import { promos } from '../data/promos';

interface SalesCalloutGroomsFreeProps {
  ctaPageName: string;
}

type Props = React.FC<React.HTMLAttributes<HTMLAnchorElement> & SalesCalloutGroomsFreeProps>;

const SalesCalloutGroomsFree: Props = ({ className, ctaPageName, ...props }) => {
  return (
    <SalesCallout
      title="Free for Wedding Couples"
      ctaTitle="See Offer"
      calloutId="sales_callout_grooms_free"
      ctaPageName={ctaPageName}
      href="/groomsfree"
      {...props}
      className={`${className ? className : ''}`}
      image={
        <div className="inline-block max-w-full">
          <picture>
            <img
              className="block h-auto max-w-full align-middle"
              width={244}
              height={135}
              loading="lazy"
              src="https://gentux.imgix.net/1617731398_210326WeddingCouplesModule210406134922.png?fit=crop&w=244&auto=format&dpr=2"
              alt="Groomsmen in black tuxedos toasting."
            />
          </picture>
        </div>
      }
    >
      With {promos.freeTux.requirements.paidMembers} paid members your suit or tux rental is free. With{' '}
      {promos.keepIt.requirements.paidMembers} paid members you can keep your suit or tuxedo.
    </SalesCallout>
  );
};

export default SalesCalloutGroomsFree;
