export const promos = {
  keepIt: {
    code: 'KEEPIT9',
    requirements: {
      paidMembers: 9,
    },
    expiresAt: '12/31/2024',
  },
  freeTux: {
    code: 'FREETUX',
    requirements: {
      paidMembers: 5,
    },
    expiresAt: '12/31/2024',
  },
} as const;
