import { useEffect } from 'react';

/*
 Initializes YotPo Widget.
 Import this hook inside any component that renders Yotpo related content
 */
const useYotpoInit = () => {
  useEffect(() => {
    try {
      if (typeof window.yotpo !== 'undefined') {
        window.yotpo.initWidgets();
      }
    } catch (e) {
      console.error(`Encountered an issue initializing Yotpo Widget`, e);
    }
  }, []);
};

export default useYotpoInit;
